.slick-track {
    display: flex;
  }
  .slick-slider {
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    width: 100%;
    margin: 0 auto;
  }
  
  .slick-slide {
    padding-right: 3px;
  }
  
  .slick-slide:last-child {
    padding-right: 0;
  }

  .slick-list {
    margin:auto;
    
  }
  
  
  .slick-prev,
  .slick-next
  {
      font-size: 0;
      line-height: 0;
  
      position: absolute;
      top: 45%;
  
      display: block;
  
      width: 30px;
      height: 30px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      outline: none;
    border-radius: 50%;
    border: 2px solid #F1CA31;
      color: whitesmoke;
      background-color: #F1CA31;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before
  {
    color: gray;
  }
  
  .slick-prev:before,
  .slick-next:before
  {
      font-family: 'slick';
      color: #F1CA31;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
  .slick-prev
  {
      left: -350px;
  }
  [dir='rtl'] .slick-prev
{
    right: -35px;
    left: auto;
}

.slick-next
{
    right: -35px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -35px;
}
  
  .slick-prev:focus,
  .slick-prev:hover,
  .slick-next:hover,
  .slick-next:focus {
    color: #F1CA31;
    background-color: transparent;
    outline: none;
    border: 2px solid transparent;
  }

  .product-actions {
    display: flex;
    justify-content: space-between;
  }
  /* .hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 100px;
    height: 100px;
    overflow: hidden;
  } */
  /* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 30px;
    height: 30px;
    margin: 0 ;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 30px;
    height: 30px;
    padding: 5px;

    cursor: pointer;

    color: #F1CA31;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 30px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    content: '•';
    text-align: center;

    opacity: .25;
    color: #F1CA31;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: #F1CA31;
}

.pageClassName{
   display: inline;
   background-color: gray;
   padding: 5px 10px 5px 10px;
   margin: 5px;
}
.pageLinkClassName{
  color: #F1CA31;
}


.slick-dots
{
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 5px;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 50px;
    height: 50px;
    margin: 0 ;
    padding: 0;
    color: black;
    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 50px;
    height: 50px;
    padding: 15px;

    cursor: pointer;

    color: purple;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}

.parallax {
    background-image: url('../img/about.png'); /* Replace with your image URL */

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
 
  }
  .parallax2 {
    background-image: url('../img/backgrouond.jpg'); /* Replace with your image URL */

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
 
  }
