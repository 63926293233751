/* src/index.css */
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.fade {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
}

.fade.in {
  opacity: 1;
}

.fade.out {
  opacity: .5;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.scale {
  transition: transform 20.5s ease;
  transform: scale(1.5);
}
.scaleOut {
  transform: scale(1);
}


.wrapper a{
  display: block;
  position: relative;
  transition: all .35s;
}

.wrapper a span{
  position: relative;
  z-index: 2;
}

.wrapper a:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #E8BD55;
  transition: all .45s;
}
.fromRight a:after{
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #fff;
  transition: all .45s;
}

.wrapper a:hover{
  color: black;
}

.wrapper a:hover:after{
  width: 100%;
}
